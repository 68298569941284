import r from '~/utils/render-utils';
import UserProfileRoutes from '~/router/user/profile';

/*
 * Documentation can be found here, https://router.vuejs.org/.
 * Meanwhile, the example can be found here, https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js
 */

const PageDashboard = () => import(/* webpackChunkName: 'home' */ '~/pages/user/PageUserDashboard');
const PageAttendance = () => import(/* webpackChunkName: 'attendance' */ '~/pages/user/PageUserAttendance');
const PageProfile = () => import(/* webpackChunkName: 'profile' */ '~/pages/user/PageUserProfile');
const PageStorage = () => import(/* webpackChunkName: 'storage' */ '~/pages/user/PageUserStorage');
const PageSchedule = () => import(/* webpackChunkName: 'schedule' */ '~/pages/user/PageUserSchedule');
const PageSalary = () => import(/* webpackChunkName: 'salary' */ '~/pages/user/PageUserSalary');
const PageAnnouncement = () => import(/* webpackChunkName: 'announcement' */ '~/pages/user/announcement/Index');

const PageUserExpenseIndex = () => import(/* webpackChunkName: 'expense_index' */ '~/pages/user/expense/PageUserExpenseIndex');
const PageUserExpenseCreate = () => import(/* webpackChunkName: 'expense_create' */ '~/pages/user/expense/PageUserExpenseCreate');
const PageUserExpenseUpdate = () => import(/* webpackChunkName: 'expense_update' */ '~/pages/user/expense/PageUserExpenseUpdate');
const PageUserExpenseHistory = () => import(/* webpackChunkName: 'expense_history' */ '~/pages/user/expense/PageUserExpenseHistory');
const PageUserExpenseDetail = () => import(/* webpackChunkName: 'expense_history' */ '~/pages/user/expense/PageUserExpenseDetail');

const PageUserLeaveIndex = () => import(/* webpackChunkName: 'leave_index' */ '~/pages/user/leave/PageUserLeaveIndex');
const PageUserLeaveCreate = () => import(/* webpackChunkName: 'leave_create' */ '~/pages/user/leave/PageUserLeaveCreate');
const PageUserLeaveUpdate = () => import(/* webpackChunkName: 'leave_update' */ '~/pages/user/leave/PageUserLeaveUpdate');
const PageUserLeaveHistory = () => import(/* webpackChunkName: 'leave_history' */ '~/pages/user/leave/PageUserLeaveHistory');
const PageUserLeaveDetail = () => import(/* webpackChunkName: 'leave_history' */ '~/pages/user/leave/PageUserLeaveDetail');
const PageUserLeaveBalance = () => import(/* webpackChunkName: 'leave_balance' */ '~/pages/user/leave/PageUserLeaveBalance');

const PageUserPayrollIndex = () => import(/* webpackChunkName: 'payroll_index' */ '~/pages/user/payroll/Index');
const PageUserPayrollList = () => import(/* webpackChunkName: 'payroll_list' */ '~/pages/user/payroll/List');
const PageUserPayrollFinanceDocList = () => import(/* webpackChunkName: 'payroll_finance_doc_list' */ '~/pages/user/payroll/FinanceDocList');

const PageUserScheduleIndex = () => import(/* webpackChunkName: 'schedule_index' */ '~/pages/user/schedule/Index');
const PageUserDepartmentScheduleList = () => import(/* webpackChunkName: 'department_schedule' */ '~/pages/user/schedule/DepartmentScheduleList');
const PageUserDepartmentScheduleDetail = () => import(/* webpackChunkName: 'department_schedule_detail' */ '~/pages/user/schedule/DepartmentScheduleDetail');

const PageUserBusinessTripIndex = () => import(/* webpackChunkName: 'business_trip_index' */ '~/pages/user/business-trip/PageUserBusinessTripIndex');
const PageUserBusinessTripCreate = () => import(/* webpackChunkName: 'business_trip_create' */ '~/pages/user/business-trip/PageUserBusinessTripCreate');
const PageUserBusinessTripUpdate = () => import(/* webpackChunkName: 'business_trip_update' */ '~/pages/user/business-trip/PageUserBusinessTripUpdate');
const PageUserBusinessTripList = () => import(/* webpackChunkName: 'business_trip_list' */ '~/pages/user/business-trip/PageUserBusinessTripList');
const PageUserBusinessTripDetail = () => import(/* webpackChunkName: 'business_trip_detail' */ '~/pages/user/business-trip/PageUserBusinessTripDetail');

const PageUserAppraisalIndex = () => import(/* webpackChunkName: 'appraisal_index' */ '~/pages/user/appraisal/PageUserAppraisalIndex');
const PageUserAppraisalList = () => import(/* webpackChunkName: 'appraisal_list' */ '~/pages/user/appraisal/PageUserAppraisalList');
const PageUserAppraisalMyList = () => import(/* webpackChunkName: 'appraisal_my_list' */ '~/pages/user/appraisal/PageUserAppraisalMyList');
const PageUserAppraisalDetail = () => import(/* webpackChunkName: 'appraisal_detail' */ '~/pages/user/appraisal/PageUserAppraisalDetail');

export default [
  { path: '/dashboard', component: r(PageDashboard), name: 'user_dashboard' },
  { path: '/announcement', component: r(PageAnnouncement), name: 'user_announcement' },
  { path: '/attendance', component: r(PageAttendance), name: 'user_attendance' },
  ...UserProfileRoutes,
  // { path: '/profile', component: r(PageProfile), name: 'profile' },
  { path: '/storage', component: r(PageStorage), name: 'user_storage' },
  {
    path: '/leave',
    component: r(PageUserLeaveIndex),
    children: [
      { path: '', redirect: '/leave/history', name: 'user_leave_index' },
      { path: 'history', component: r(PageUserLeaveHistory), name: 'user_leave_history' },
      { path: 'balance', component: r(PageUserLeaveBalance), name: 'user_leave_balance' },
      { path: 'request', component: r(PageUserLeaveCreate), name: 'user_leave_request' },
      { path: 'update/:id(\\d+)', component: r(PageUserLeaveUpdate), name: 'user_leave_update' },
      { path: 'detail/:id(\\d+)', component: r(PageUserLeaveDetail), name: 'user_leave_detail' },
    ],
  },
  {
    path: '/expense',
    component: r(PageUserExpenseIndex),
    children: [
      { path: '', redirect: '/expense/history', name: 'user_expense_index' },
      { path: 'history', component: r(PageUserExpenseHistory), name: 'user_expense_history' },
      { path: 'request', component: r(PageUserExpenseCreate), name: 'user_expense_request' },
      { path: 'update/:id(\\d+)', component: r(PageUserExpenseUpdate), name: 'user_expense_update' },
      { path: 'detail/:id(\\d+)', component: r(PageUserExpenseDetail), name: 'user_expense_detail' },
    ],
  },
  {
    path: '/my-schedule',
    component: r(PageUserScheduleIndex),
    children: [
      { path: '', component: r(PageSchedule), name: 'user_schedule' },
      { path: 'department', component: r(PageUserDepartmentScheduleList), name: 'user_schedule_department_list' },
      { path: 'department/:department/year/:year/month/:month/date/:date/:id', component: r(PageUserDepartmentScheduleDetail), name: 'user_schedule_department_detail' },
    ],
  },
  {
    path: '/payroll',
    component: r(PageUserPayrollIndex),
    children: [
      { path: '', component: r(PageUserPayrollList), name: 'user_payroll_list' },
      { path: 'finance-doc', component: r(PageUserPayrollFinanceDocList), name: 'user_payroll_finance_doc_list' },
    ],
  },
  {
    path: '/business-travel',
    component: r(PageUserBusinessTripIndex),
    children: [
      { path: 'list', component: r(PageUserBusinessTripList), name: 'user_business_trip_list' },
      { path: 'request', component: r(PageUserBusinessTripCreate), name: 'user_business_trip_request' },
      { path: 'update/:id(\\d+)', component: r(PageUserBusinessTripUpdate), name: 'user_business_trip_update' },
      { path: 'detail/:id(\\d+)', component: r(PageUserBusinessTripDetail), name: 'user_business_trip_detail' },
    ],
  },
  {
    path: '/performance-appraisal',
    component: r(PageUserAppraisalIndex),
    children: [
      { path: 'list', component: r(PageUserAppraisalList), name: 'user_appraisal_list' },
      { path: 'my-review', component: r(PageUserAppraisalMyList), name: 'user_appraisal_my_list' },
      { path: 'my-review/:id(\\d+)/view/:formId(\\d+)', component: r(PageUserAppraisalDetail), name: 'user_appraisal_personal' },
      { path: ':id(\\d+)/view/:formId(\\d+)', component: r(PageUserAppraisalDetail), name: 'user_appraisal_detail' },
    ],
  },
];
