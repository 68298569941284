/* eslint-disable class-methods-use-this, no-useless-catch */
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import https from 'https';
import { generateParams } from '~/api/fetch-helper';
import { popupTransfer, redirectLogin, switchCompany } from '~/helpers/company';

const DEFAULT_REQUEST_ATTEMPT_LIMIT = 1;
const DEFAULT_REQUEST_TIME_LIMIT = 8500; // in ms

class RequestManager {
  constructor(client, baseURL = '', requestLaneKey = 'requestLane') {
    this.debug = process.env.NODE_ENV === 'development';
    this.axios = client;
    this.baseURL = baseURL;
    this.cancelTokenSource = client.CancelToken.source();
    this.requestLaneKey = requestLaneKey;
  }

  async request(url, method, scope, inputOptions) {
    const defaults = RequestManager.defaultRequestOptions();

    this.requestKey = `${this.requestLaneKey}-${url.replace(/\/\w+\d+\w+/, '')}-${method}`;
    if (inputOptions.requestLimit && inputOptions.requestLimit.additionalKey) this.requestKey += `-${inputOptions.requestLimit.additionalKey}`;
    if (inputOptions.requestLimit && this.needToLimitRequest(inputOptions.requestLimit)) return Promise.reject(new Error('REQUEST LIMITED'));

    const requestId = uuidv4();
    const headers = { ...defaults.headers, ...inputOptions.headers };
    const options = { ...defaults, ...inputOptions, ...{ headers } };

    let cts = this.cancelTokenSource;

    if (inputOptions.cancelTokenSource) {
      cts = inputOptions.cancelTokenSource;
    }

    this.registerCancelToken(requestId, cts);

    return this.requestWithToken(async tokenObj => {
      const requestParam = this.generateRequestParam(url, method, requestId, tokenObj, cts, options);

      try {
        const res = await this.axios.request(requestParam).catch(err => {
          // If unauthorized
          if (err && typeof err.response !== 'undefined' && err.response.status === 401) {
            window.location = '/logout';
          } else if (err && typeof err.response !== 'undefined' && err.response.status === 404 && err.response.data && err.response.data.is_not_accessible) {
            window.location = '/404';
          } else if (err && typeof err.response !== 'undefined' && err.response.status === 403) {
            // handler ketika company yg diakses status inactive
            if (err.response.data && err.response.data.is_inactive) {
              if (err.response.data.data && err.response.data.data.length) {
                switchCompany(err.response.data.data);
              } else {
                redirectLogin();
              }
            }
            
            // handler ketika employee sedang proses transfer ke company lain
            if (err.response.data && err.response.data.is_transfer) {
              popupTransfer();
            }

            // jika bukan karna kondisi diatas, maka dibalikkan ke route sebelumnya 
            window.history.go(-1);
          } else if (err && typeof err.response !== 'undefined') {
            throw err;
          }
        });

        this.removeCancelToken(requestId);
        this.removeLimitedRequest(inputOptions.requestLimit);

        if (typeof res !== 'undefined' && res.data) {
          return res.data;
        }

        return { data: null };
      } catch (e) {
        // if (this.debug) console.info(e);
        this.handleRequestRejection(e, requestId, options);
        this.removeLimitedRequest(inputOptions.requestLimit);

        throw e;
        // return e;
      }
    });
  }

  needToLimitRequest(requestLimit) {
    if (!requestLimit.attempt && !requestLimit.time) return false;
    const attemptLimit = requestLimit.attempt || DEFAULT_REQUEST_ATTEMPT_LIMIT;
    const timeLimit = requestLimit.time || DEFAULT_REQUEST_TIME_LIMIT;
    const { limitedRequestPool } = RequestManager;
    const limitedRequest = limitedRequestPool.get(this.requestKey);
    if (!limitedRequest) {
      this.registerLimitedRequest(requestLimit);
      return false;
    }
    const { attempt, requestedAt } = limitedRequest;
    const isLimitedByAttempt = attempt > attemptLimit - 1;
    const isLimitedByTime = new Date(requestedAt).getTime() + timeLimit > new Date().getTime();
    if (!isLimitedByTime) {
      this.registerLimitedRequest(requestLimit);
      return false;
    }
    if (isLimitedByAttempt) return true;
    limitedRequestPool.set(this.requestKey, { attempt: attempt + 1, requestedAt });
    this.removeLimitedRequest(requestLimit);
    return false;
  }

  registerLimitedRequest(requestLimit) {
    const timeLimit = requestLimit.time || DEFAULT_REQUEST_TIME_LIMIT;
    const { limitedRequestPool } = RequestManager;
    limitedRequestPool.set(this.requestKey, { attempt: 1, requestedAt: Date.now() });
    setTimeout(() => {
      this.removeLimitedRequest(requestLimit);
    }, timeLimit + 1);
  }

  removeLimitedRequest(requestLimit) {
    if (!requestLimit) return;
    const { limitedRequestPool } = RequestManager;
    const limitedRequest = limitedRequestPool.get(this.requestKey);
    if (!limitedRequest) return;
    const { attempt, requestedAt } = limitedRequest;
    const timeLimit = requestLimit.time || DEFAULT_REQUEST_TIME_LIMIT;
    const isExpired = new Date(requestedAt).getTime() + timeLimit < new Date().getTime();
    if (!isExpired) return;
    if (attempt <= 1) {
      limitedRequestPool.delete(this.requestKey);
    } else {
      limitedRequestPool.set(this.requestKey, { attempt: attempt - 1, requestedAt });
    }
  }

  requestWithToken(callback) {
    const tokenResponse = localStorage.getItem('auth._token.local');
    return callback(tokenResponse);
  }

  handleRequestRejection(rejection, requestId, options) {
    this.removeCancelToken(requestId);

    if (this.axios.isCancel(rejection)) {
      // request cancelled, do nothing lalala…
    } else {
      // throw errors, they should be handled by the interface
      if (options.responseErrorJson && rejection.response) {
        throw rejection.response.data;
      }

      throw rejection;
    }
  }

  /**
   * Aborts currently active requests in the request pool with the same key
   */
  abortRequest() {
    const { requestPool } = RequestManager;
    const requestLane = requestPool.get(this.requestLaneKey);

    if (requestLane) {
      requestLane.forEach(cancelToken => {
        cancelToken.cancel('Request aborted');
      });

      requestLane.clear();
    }

    requestPool.delete(this.requestLaneKey);
  }

  generateRequestParam(url, method, requestId, tokenObj, cancelTokenSource, options) {
    const agent = new https.Agent({
      rejectUnauthorized: false,
    });

    const params = {
      ...options,
      url,
      method,
      httpsAgent: agent,
      baseURL: this.baseURL,
      cancelToken: cancelTokenSource.token,
    };

    if (options.headers['Content-Type'] === 'application/octet-stream') {
      params.responseType = 'blob';
    }

    if (options.enableRequestId) {
      Object.assign(params.headers, { 'X-Request-Id': requestId });
    }

    if (options.enableAuthHeader) {
      params.headers.Authorization = `${tokenObj}`;
      if (localStorage.getItem('user')) {
        const user = JSON.parse(localStorage.getItem('user'));
        params.headers['X-User-Id'] = user.uuid || '';
      }
    }

    if (options.enableCompanyId) {
      if (options.customCompanyId) {
        params.headers['X-Company-Id'] = options.customCompanyId;
      } else if (Vue.$cookies.get('selected_company')) {
        const company = Vue.$cookies.get('selected_company');
        params.headers['X-Company-Id'] = company.id || '';
      }
    }

    return params;
  }

  registerCancelToken(requestId, cancelTokenSource) {
    const { requestPool } = RequestManager;
    let requestLane = requestPool.get(this.requestLaneKey);

    if (!requestLane) {
      requestLane = new Map();
      requestPool.set(this.requestLaneKey, requestLane);
    }

    requestLane.set(requestId, cancelTokenSource);
  }

  removeCancelToken(requestId) {
    const { requestPool } = RequestManager;
    const requestLane = requestPool.get(this.requestLaneKey);

    if (requestLane) {
      requestLane.delete(requestId);

      if (requestLane.size === 0) {
        requestPool.delete(this.requestLaneKey);
      }
    }
  }
}

RequestManager.requestPool = new Map();
RequestManager.limitedRequestPool = new Map();

RequestManager.defaultRequestOptions = function defaultRequestOptions() {
  return {
    data: {},
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {},
    enableRequestId: false,
    responseErrorJson: false,
    enableAuthHeader: true,
    enableCompanyId: true,
    timeout: 0,
  };
};

['GET', 'POST', 'PATCH', 'PUT', 'DELETE'].forEach(method => {
  const methodName = method.toLowerCase();
  RequestManager.prototype[methodName] = function requestWrapper(url, scope = 'public', options = {}) {
    let fullUrl = url;
    if (methodName === 'get') {
      fullUrl += generateParams(options.data);
    }
    return this.request(fullUrl, method, scope, options);
  };
});
export default RequestManager;
